import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { oauth } from "../utils";
import { ScheduledReport } from "../types/scheduled_reports";

const BASE_URL = "scheduled-report";

const adapter = createEntityAdapter({
  selectId: (scheduledReport: ScheduledReport) =>
    scheduledReport.scheduled_report_id,
});

interface ScheduledReportState {
  loading: "idle" | "pending";
  success: boolean;
  currentSchedule: ScheduledReport;
}

const getDefaultScheduledReport = (
  user_id: string = null,
): ScheduledReport => ({
  report_id: "",
  frequency: "MONTH",
  month_day: -1,
  week_day: "MONDAY",
  scheduled_report_recipients: user_id ? [user_id] : [],
});

const initialState: ScheduledReportState = {
  loading: "idle",
  success: false,
  currentSchedule: getDefaultScheduledReport(),
};

const slice = createSlice({
  name: "scheduledReports",
  initialState: adapter.getInitialState(initialState),
  reducers: {
    loading(state) {
      state.loading = "pending";
      state.success = false;
    },
    loaded(state) {
      state.loading = "idle";
    },
    created(state, action) {
      adapter.addOne(state, action.payload);
      state.loading = "idle";
      state.success = true;
      state.currentSchedule = action.payload;
    },
    retrieved(state, action) {
      adapter.setAll(state, action.payload);
      state.loading = "idle";
    },
    updated(state, action) {
      adapter.updateOne(state, {
        id: action.payload.id,
        changes: action.payload,
      });
      state.loading = "idle";
      state.success = true;
      state.currentSchedule = action.payload;
    },
    deleted(state, action) {
      adapter.removeOne(state, action.payload);
      state.loading = "idle";
      state.currentSchedule = getDefaultScheduledReport();
    },
    setCurrentSchedule(state, action) {
      state.currentSchedule = action.payload;
    },
    resetCurrentSchedule(state, action) {
      state.currentSchedule = getDefaultScheduledReport(action.payload);
      state.success = false;
    },
    updateReportId(state, action) {
      state.currentSchedule.report_id = action.payload;
    },
    updateFrequency(state, action) {
      state.currentSchedule.frequency = action.payload;
    },
    updateMonthDay(state, action) {
      state.currentSchedule.month_day = action.payload;
    },
    updateWeekDay(state, action) {
      state.currentSchedule.week_day = action.payload;
    },
    updateSendTo(state, action) {
      state.currentSchedule.scheduled_report_recipients = [action.payload];
    },
  },
});

export const actions = slice.actions;

export const fetchScheduledReports =
  () =>
  async (dispatch): Promise<boolean | ScheduledReport[]> => {
    dispatch(actions.loading());

    try {
      const response = await oauth("GET", BASE_URL, {});

      if (!response.json) {
        throw new Error("Failed to fetch scheduled reports");
      }

      dispatch(actions.retrieved(response.json.scheduled_reports));
      return response.json.scheduled_reports;
    } catch (err) {
      dispatch(actions.loaded());
      toast.error("Failed to fetch scheduled reports");
      return false;
    }
  };

const createScheduledReport =
  (scheduledReport: ScheduledReport) => async (dispatch) => {
    if (!isValidSchedule(scheduledReport)) {
      toast.error("Please fill in all required fields");
      return false;
    }

    dispatch(actions.loading());

    try {
      const response = await oauth("POST", BASE_URL, scheduledReport);

      if (!response.json) {
        throw new Error("Failed to schedule report");
      }

      dispatch(actions.created(response.json.scheduled_report));
      return;
    } catch (err) {
      dispatch(actions.loaded());
      toast.error("Failed to schedule report");
      return false;
    }
  };

const updateScheduledReport =
  (scheduledReport: ScheduledReport) => async (dispatch) => {
    if (!isValidSchedule(scheduledReport)) {
      toast.error("Please fill in all required fields");
      return false;
    }

    dispatch(actions.loading());

    try {
      const response = await oauth(
        "PUT",
        `${BASE_URL}/${scheduledReport.scheduled_report_id}`,
        scheduledReport,
      );

      if (!response.json) {
        throw new Error("Failed to update scheduled report");
      }

      dispatch(actions.updated(response.json.scheduled_report));
      return;
    } catch (err) {
      dispatch(actions.loaded());
      toast.error("Failed to update scheduled report");
      return false;
    }
  };

export const unscheduleReport = (id: string) => async (dispatch) => {
  dispatch(actions.loading());
  try {
    const response = await oauth("DELETE", `scheduled-report/${id}`, {});

    if (response.response.status !== 204) {
      throw new Error("Failed to unschedule report");
    }

    dispatch(actions.deleted(id));
    return true;
  } catch (err) {
    dispatch(actions.loaded());
    toast.error("Failed to unschedule report");
    return false;
  }
};

export const saveScheduledReport =
  (data?: ScheduledReport) => (dispatch, getState) => {
    const scheduledReport = data || getState().scheduledReports.currentSchedule;
    const monthDay =
      scheduledReport.month_day === -1 ? null : scheduledReport.month_day;

    if (scheduledReport.scheduled_report_id) {
      return dispatch(
        updateScheduledReport({ ...scheduledReport, month_day: monthDay }),
      );
    } else {
      return dispatch(
        createScheduledReport({ ...scheduledReport, month_day: monthDay }),
      );
    }
  };

const isValidSchedule = (schedule: ScheduledReport): boolean => {
  const isValidReport = schedule.report_id !== "";
  const isValidFrequency =
    schedule.frequency === "MONTH"
      ? schedule.month_day === -1 ||
        (schedule.month_day >= 1 && schedule.month_day <= 31)
      : [
          "MONDAY",
          "TUESDAY",
          "WEDNESDAY",
          "THURSDAY",
          "FRIDAY",
          "SATURDAY",
          "SUNDAY",
        ].includes(schedule.week_day);
  const isValidSendTo = schedule.scheduled_report_recipients.length === 1;

  return isValidReport && isValidFrequency && isValidSendTo;
};

export const selectors = adapter.getSelectors(
  (state: any) => state.scheduledReports,
);

export const selectCurrentSchedule = (state) =>
  state.scheduledReports.currentSchedule;
export const selectLoading = (state) =>
  state.scheduledReports.loading === "pending";
export const selectSuccess = (state) => state.scheduledReports.success;
export const selectIsNew = (state) =>
  !state.scheduledReports.currentSchedule.scheduled_report_id;

export const selectIsValid = (state) => {
  return isValidSchedule(state.scheduledReports.currentSchedule);
};

export default slice.reducer;
