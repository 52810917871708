import React, { useState } from "react";

import {
  LabeledSelect,
  Row,
  Col,
  Label,
  Toggle,
  ToggleLink,
  Button,
  AlertNotification,
  Text,
  Popup,
  PopupProps,
} from "@commonsku/styles";

import EmailList from "../../EmailList";
import FormPopup from "../../FormPopup";
import withReducers from "../../../store/withReducers";
import { BASE_ZINDEX } from "../../../popup-factory";

import { useScheduledReports } from "./useScheduledReport";
import savedReportsReducer from "../../../redux/savedReports";
import scheduledReportsReducer from "../../../redux/scheduledReports";

interface ScheduleReportPopupProps {
  onClose: () => void;
  report_id?: string;
}

export const DAYS_OF_MONTH = [
  {
    label: "last day of the month",
    value: "-1",
  },
].concat(
  Array.from({ length: 31 }, (_, i) => ({
    label: String(i + 1),
    value: String(i + 1),
  })),
);

export const DAYS_OF_WEEK = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
].map((day) => ({ label: day, value: day.toUpperCase() }));

interface ConfirmUnschedulePopupProps extends PopupProps {
  onClose: () => void;
  onConfirm: () => void;
  message: string;
}

const ConfirmUnschedulePopup = ({
  onClose,
  onConfirm,
  message,
  ...popupProps
}: ConfirmUnschedulePopupProps) => (
  <Popup width="438px" height="auto" noHeader {...popupProps}>
    <Row mb={24}>
      <Col>
        <Text color="neutrals" style={{ fontSize: 18, lineHeight: "28px" }}>
          {message}
        </Text>
      </Col>
    </Row>
    <Row flex>
      <Col center>
        <Button mr={24} onClick={onClose} variant="secondary">
          Cancel
        </Button>
        <Button variant="error" onClick={onConfirm}>
          Unschedule
        </Button>
      </Col>
    </Row>
  </Popup>
);

const ScheduleReportPopup = ({
  onClose,
  report_id = "",
}: ScheduleReportPopupProps) => {
  const [showConfirm, setShowConfirm] = useState<boolean>(false);

  const {
    state: { schedule, success, isValid, isNew },
    operations: { saveScheduledReport, unscheduleReport },
    updater,
    options: { savedReportOptions, sendToOptions },
    selections: { reportOption, monthDay, weekDay, sendTo },
  } = useScheduledReports(report_id);

  const isEditable = true;

  const handleClose = () => {
    updater.reset();
    onClose();
  };

  const formContent = (
    <>
      <Row>
        <Col>
          <LabeledSelect
            inPopup
            label="Saved Report"
            options={savedReportOptions}
            value={reportOption}
            onChange={(option: any) => updater.setReportId(option.value)}
            isDisabled={!isNew || !!report_id}
          />
        </Col>
      </Row>
      {reportOption && (
        <>
          <Row mt={16}>
            <Col>
              <Label>Frequency</Label>
              <Toggle>
                <ToggleLink
                  selected={schedule.frequency === "MONTH"}
                  onClick={() => updater.setFrequency("MONTH")}
                >
                  Monthly
                </ToggleLink>
                <ToggleLink
                  selected={schedule.frequency === "WEEK"}
                  onClick={() => updater.setFrequency("WEEK")}
                >
                  Weekly
                </ToggleLink>
              </Toggle>
            </Col>
            <Col>
              {schedule.frequency === "MONTH" ? (
                <LabeledSelect
                  inPopup
                  label="Day of Month"
                  value={monthDay}
                  options={DAYS_OF_MONTH}
                  onChange={(option: any) =>
                    updater.setMonthDay(Number(option.value))
                  }
                />
              ) : (
                <LabeledSelect
                  inPopup
                  label="Day of week"
                  value={weekDay}
                  options={DAYS_OF_WEEK}
                  onChange={(option: any) => updater.setWeekDay(option.value)}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <EmailList
                label="Send To"
                value={sendTo}
                options={sendToOptions}
                onChange={(option: any) => updater.setSendTo(option.value)}
                inPopup
                isMulti={false}
                showDropdownIndicator
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );

  const successContent = success && (
    <AlertNotification alertType="success">
      <Text as="p" color="secondary3">
        <Text bold as="span" color="secondary3">
          {reportOption.label}
        </Text>
        &nbsp; has been scheduled! An email will be sent to {sendTo.email}&nbsp;
        {schedule.frequency === "MONTH" ? "monthly" : "weekly"}.
      </Text>
    </AlertNotification>
  );

  const customActions = success ? (
    <Button onClick={handleClose}>Close</Button>
  ) : (
    <>
      <Button secondary onClick={handleClose}>
        Cancel
      </Button>
      {!isNew && (
        <Button variant="error" onClick={() => setShowConfirm(true)}>
          Unschedule
        </Button>
      )}
      <Button disabled={!isValid} onClick={saveScheduledReport}>
        {isNew ? "Schedule" : "Save"}
      </Button>
    </>
  );

  const handleLeftArrowClick = () => {
    updater.reset();
  };

  return (
    <FormPopup
      isNew={isNew}
      isEditable={isEditable}
      isValid={isValid}
      onClose={handleClose}
      title="Schedule Report"
      width="575px"
      height="450px"
      customActions={customActions}
      zIndex={BASE_ZINDEX + 200}
      overlayZIndex={BASE_ZINDEX + 100}
      onLeftArrowClick={handleLeftArrowClick}
    >
      {showConfirm && (
        <ConfirmUnschedulePopup
          zIndex={BASE_ZINDEX + 400}
          overlayZIndex={BASE_ZINDEX + 300}
          onClose={() => setShowConfirm(false)}
          onConfirm={() => {
            unscheduleReport().then(() => setShowConfirm(false));
            updater.reset();
          }}
          message="Are you sure you want to unschedule this report?"
        />
      )}
      {success ? successContent : formContent}
    </FormPopup>
  );
};

export default withReducers(ScheduleReportPopup, {
  savedReports: savedReportsReducer,
  scheduledReports: scheduledReportsReducer,
});
