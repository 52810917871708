import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  actions,
  fetchScheduledReports as fetchScheduledReportsAction,
  saveScheduledReport as saveScheduledReportAction,
  unscheduleReport as unscheduleReportAction,
  selectCurrentSchedule,
  selectLoading,
  selectSuccess,
  selectIsValid,
  selectIsNew,
  selectors,
} from "../../../redux/scheduledReports";
import { ScheduledReport } from "../../../types/scheduled_reports";
import { fetchSavedReports } from "../../../redux/savedReports";
import { useIdentity } from "../../../hooks";
import {
  getSavedReportsOption,
  getUsersForScheduledReports,
} from "../../../selectors/dropdowns";
import { DAYS_OF_MONTH, DAYS_OF_WEEK } from "./ScheduleReportPopup";

export function useScheduledReports(report_id?: string) {
  const dispatch = useDispatch();
  const identity = useIdentity();

  const schedule: ScheduledReport = useSelector(selectCurrentSchedule);
  const loading = useSelector(selectLoading);
  const success = useSelector(selectSuccess);
  const isValid = useSelector(selectIsValid);
  const isNew = useSelector(selectIsNew);

  const savedReportOptions = useSelector(getSavedReportsOption);
  const sendToOptions = useSelector(getUsersForScheduledReports);

  const currentUserId =
    sendToOptions.find((u) => u.value === identity.user_id)?.value ?? "";

  const operations = useMemo(
    () => ({
      fetchScheduledReports: async () =>
        dispatch(fetchScheduledReportsAction()),

      saveScheduledReport: async () => {
        if (!isValid) {
          toast.error("Please fill in all required fields");
          return false;
        }
        if (loading) return false;
        return dispatch(saveScheduledReportAction());
      },

      unscheduleReport: async () => {
        if (!schedule.scheduled_report_id) {
          toast.error("No report selected to unschedule");
          return false;
        }
        return dispatch(unscheduleReportAction(schedule.scheduled_report_id));
      },
    }),
    [dispatch, isValid, loading, schedule.scheduled_report_id],
  );

  const updater = useMemo(
    () => ({
      setReportId: (report_id: string) => {
        dispatch((dispatch, getState) => {
          const reports = selectors.selectAll(getState());
          const existingSchedule = reports.find(
            (sr) => sr.report_id === report_id,
          );
          if (existingSchedule) {
            dispatch(actions.setCurrentSchedule(existingSchedule));
          } else {
            dispatch(actions.resetCurrentSchedule(currentUserId));
            dispatch(actions.updateReportId(report_id));
          }
        });
      },
      setFrequency: (frequency: ScheduledReport["frequency"]) =>
        dispatch(actions.updateFrequency(frequency)),
      setMonthDay: (month_day: ScheduledReport["month_day"]) =>
        dispatch(actions.updateMonthDay(month_day)),
      setWeekDay: (week_day: ScheduledReport["week_day"]) =>
        dispatch(actions.updateWeekDay(week_day)),
      setSendTo: (send_to: string) => dispatch(actions.updateSendTo(send_to)),
      reset: () => dispatch(actions.resetCurrentSchedule(currentUserId)),
    }),
    [currentUserId, dispatch],
  );

  const sendToValue = schedule.scheduled_report_recipients?.[0] ?? "";

  const selections = useMemo(
    () => ({
      reportOption:
        savedReportOptions.find((r) => r.value === schedule.report_id) ?? null,
      monthDay: DAYS_OF_MONTH.find(
        (d) => Number(d.value) === schedule.month_day,
      ),
      weekDay: DAYS_OF_WEEK.find((d) => d.value === schedule.week_day),
      sendTo: sendToOptions.find((c) => c.value === sendToValue),
    }),
    [
      savedReportOptions,
      sendToOptions,
      schedule.report_id,
      schedule.month_day,
      schedule.week_day,
      sendToValue,
    ],
  );

  useEffect(() => {
    dispatch(fetchScheduledReportsAction());
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchSavedReports());
      if (report_id) {
        updater.setReportId(report_id);
      }
    };
    fetchData();
  }, [dispatch, report_id, updater]);

  useEffect(() => {
    if (currentUserId && isNew) {
      updater.setSendTo(currentUserId);
    }
  }, [currentUserId, isNew, updater]);

  return {
    state: { schedule, loading, success, isValid, isNew },
    operations,
    updater,
    options: { savedReportOptions, sendToOptions },
    selections,
  };
}
